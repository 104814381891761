body {
  margin: 0;
  font-family:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #11111f; /* Add your preferred background color */
  color:#ffffff;
}

.logo {
  max-width: 100px; /* Adjust the max-width based on your logo size */
}

header {
 /* margin-bottom: 20px;*/
}

.content-container {
  max-width: 800px; /* Adjust the max-width based on your content width */
  width: 100%;
}
/* Default styles for smaller screens */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.logo {
  max-width: 100%;
}

/* Adjust layout for larger screens */
@media (min-width: 768px) {
  .app-container {
    flex-direction: row;
  }

  .content-container {
    flex: 1;
    padding: 20px; /* Add padding to content on the right */
  }

  .logo {
    max-width: 200px; /* Adjust the max-width as needed */
    margin-right: 20px; /* Add margin to the right of the logo */
   
  }
  .logo-column{
    text-align: center;
  }
}
.header {
  font-size: 2.5rem;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
}
.subtitle {
  font-size: 1.2rem;
  color: #bdd0e0;
  margin-bottom: 20px;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
}

.app{
  background-color: #11111f; /* Add your preferred background color */
  color:#ffffff;
}


.form-control .is-invalid{
  border-color:orange !important;
}



.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75
}


.prose :where(h2):not(:where([class~="not-prose"] *)) {
  
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
  color: inherit
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
 
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: .6em;
  line-height: 1.6
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
  color: inherit
}

.secondary-link{
  color:white !important;
  cursor: pointer;
}
.custom-header {
  align-self: flex-start;

}

/* Apply padding only on desktop */
@media (min-width: 768px) {
  .custom-header {
    padding-top: 100px;
  }
    .reasons{
    max-width:70% !important
  }
}

/* Apply padding only on mobile */
@media (max-width: 768px) {
  .custom-header {
   width: 100%;
  }
  .content-container {
    min-height: 490px;
  }
  .callout-container {
    text-align: center;
  }
  .reasons{
    max-width:100% !important
  }

}
.ai-writing{
  font-family: 'Courier New', Courier, monospace;
}
 
#trialTabs-tab-eligible  .nav-link {
  background-color:darkseagreen !important;
} 

.underlined{
 
    border-bottom: 1px dotted white; /* Adjust color as needed */
    text-decoration: none; /* To remove the default underline */
 
}
.underlined-dark {
  border-bottom: 1px dotted black; /* Adjust color as needed */
  background-color: azure;
}
.enrollementGroupName {
  border: 1px solid #9E9E9E;
  background-color: #f5f5f5;
  float: right;
  font-weight: 500;
  padding: 4px;
  border-radius: 5px;
  font-size: smaller;
}
.enrollementGroupName.allParticipants {
  background-color: #b9d1f626 ! important;
}

.trialCard {
  display: flex;
  
}

.main-column {
  flex: 1;
}
.webpage-column {
  flex: 1;
  margin-left: 20px; /* Add margin for spacing */
  width: 2000px !important;
  background-color: white;
  color: #11111f;
}
iframe {
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  border: none; /* Remove border */
}

.zero-results {
  color: #999 !important; /* Grayed out color */
}

.non-zero-results {
  font-weight: 700 !important;
}


.zero-results span{
  color: #999; /* Grayed out color */
}


.accuracy-panel {
  color: rgb(189, 208, 224);
  border: 1px solid rgb(189, 208, 224);
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px; /* Adjust margin-top as needed */
  margin-right: 50px;;
}

.accuracy-panel h2 {
 
}

.accuracy-info span {
  
}

.inclusion-button{
  background-color: #8bc34a33;
}
.exclusion-button{
  background-color: #f080805c;
}

/* Styles for inclusion button  
.inclusion-button::before {
  content: "\25CF"; /* Unicode character for filled circle  
  margin-right: 8px;
 
}
 
.exclusion-button::before {
  content: "\25A0"; /* Unicode character for filled square  
  margin-right: 8px;
}
*/

.trial-res-tab{
  color : rgb(121, 190, 213);
}

.nav-link.active .trial-res-tab  {
  color : black;
}

.patient-input-popover {
  max-width: 500px !important; /* Adjust the width as needed */
}